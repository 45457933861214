/* ======= Animations ======= */
$(document).ready(function() {
    
    //Only animate elements when using non-mobile devices    
    if (jQuery.browser.mobile === false) {

        /* Animate elements in #promo (homepage) */
        $('#promo .intro .title').one('inview', function(isInView) {
            if (isInView) {$(this).addClass('animated fadeInLeft delayp1');}
        });
        $('#promo .intro .summary').one('inview', function(isInView) {
            if (isInView) {$(this).addClass('animated fadeInRight delayp3');}
        });
        
        
        /* Animate elements in #why (homepage) */
        $('#why .benefits').one('inview', function(isInView) {
            if (isInView) {$(this).addClass('animated fadeInLeft delayp1');}
        });
        $('#why .whois').one('inview', function(isInView) {
            if (isInView) {$(this).addClass('animated fadeInLeft delayp1');}
        });
        $('#why .testimonials').one('inview', function(isInView) {
            if (isInView) {$(this).addClass('animated fadeInRight delayp3');}
        });
        
         $('#why .btn').css('opacity', 0).one('inview', function(isInView) {
            if (isInView) {$(this).addClass('animated fadeInUp delayp6');}
        });
        
        
        /* Animate elements in #video (homepage) */
        $('#video .title').css('opacity', 0).one('inview', function(isInView) {
            if (isInView) {$(this).addClass('animated fadeInLeft delayp1');}
        });
        
        $('#video .summary').css('opacity', 0).one('inview', function(isInView) {
            if (isInView) {$(this).addClass('animated fadeInRight delayp3');}
        });
        
        
        /* Animate elements in #faq */
        $('#faq .panel').css('opacity', 0).one('inview', function(isInView) {
            if (isInView) {$(this).addClass('animated fadeInUp delayp1');}
        });
        
        $('#faq .more').css('opacity', 0).one('inview', function(isInView) {
            if (isInView) {$(this).addClass('animated fadeInUp delayp3');}
        });
    
        
        /* Animate elements in #features-promo */
        $('#features-promo .title').one('inview', function(isInView) {
            if (isInView) {$(this).addClass('animated fadeInLeft delayp1');}
        });
        
        $('#features-promo .features-list').one('inview', function(isInView) {
            if (isInView) {$(this).addClass('animated fadeInRight delayp3');}
        });
        
        $('#features-promo .video-container').one('inview', function(isInView) {
            if (isInView) {$(this).addClass('animated fadeInUp delayp6');}
        });
        
        $('#features .from-left').each(function(index){
            $(this).one('inview', function(isInView) {
                if (isInView) {$(this).addClass('animated fadeInLeft delayp1');}
            });
        });
        
        $('#features .from-right').each(function(index){
            $(this).one('inview', function(isInView) {
                if (isInView) {$(this).addClass('animated fadeInRight delayp3');}
            });
        });
        
        /* Animate elements in #price-plan */
        $('#price-plan .price-figure').css('opacity', 0).one('inview', function(isInView) {
            if (isInView) {$(this).addClass('animated fadeInUp delayp1');}
        });
        
        $('#price-plan .heading .label').css('opacity', 0).one('inview', function(isInView) {
            if (isInView) {$(this).addClass('animated fadeInDown delayp6');}
        });
        
        /* Animate elements in #blog-list */
        $('#blog-list .post').css('opacity', 0).one('inview', function(isInView) {
            if (isInView) {$(this).addClass('animated fadeInUp delayp1');}
        });
        
        /* Animate elements in #contact-main */
        $('#contact-main .item .icon').each(function(index){
            $(this).one('inview', function(isInView) {
                if (isInView) {$(this).addClass('animated fadeInUp delayp1');}
            });
        });
        
         /* Animate elements in #signup */
        
        $('#signup .form-horizontal').each(function(index){
            $(this).one('inview', function(isInView) {
                if (isInView) {$(this).addClass('animated fadeInUp delayp1');}
            });
        });

        
    }else{
        $('#promo .intro .title').css('opacity', 1);
        $('#promo .intro .summary').css('opacity', 1);
        
        
        
        $('#why .benefits').css('opacity', 1);

        $('#why .whois').css('opacity', 1);
        
        $('#why .testimonials').css('opacity', 1);
        
         $('#why .btn').css('opacity', 1);
        
        
        
        $('#video .title').css('opacity', 1);
        
        $('#video .summary').css('opacity', 1);
       
        $('#faq .panel').css('opacity', 1);
        
        $('#faq .more').css('opacity', 0).css('opacity', 1);
    
        
       
        $('#features-promo .title').css('opacity', 1);
        
        $('#features-promo .features-list').css('opacity', 1);
        
        $('#features-promo .video-container').css('opacity', 1);
        
        $('#features .from-left').each(function(index){
            $(this).css('opacity', 1);
        });
        
        $('#features .from-right').each(function(index){
            $(this).css('opacity', 1);
        });
        
       
        $('#price-plan .price-figure').css('opacity', 1);
        
        $('#price-plan .heading .label').css('opacity', 1);
        
        
        $('#blog-list .post').css('opacity', 0).css('opacity', 1);
        
      
        $('#contact-main .item .icon').each(function(index){
            $(this).css('opacity', 1);
        });
        
       
        
        $('#signup .form-horizontal').each(function(index){
            $(this).css('opacity', 1);
        });
    }
        
});